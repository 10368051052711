import React from 'react'
import Clock from 'react-live-clock';

import './Home.css';
import Logo from '../../assets/jaar-studio_logo_white.svg';
import colorsData from '../../data/ColorsData';

const Home = () => {

    const time = <Clock format={'HH:mm:ss'} ticking={true} timezone={'Europe/Tallinn'} />;

    const onMouseEnter = () => {
        document.querySelector(".cursor-small").classList.add("cursor-small--active");
    }
    
    const onMouseLeave = () => {
        document.querySelector(".cursor-small").classList.remove("cursor-small--active");
    }

    const [style, setStyle] = React.useState("white");

    const backgroundColor = style;

    let randomColor = 0;

    const backgroundColorChange = () => {
        const background = document.querySelector(".background");
        let colorNumber = Number(background.getAttribute("data-bg"));
        
        randomColor = Math.floor(Math.random() * colorsData.length);

        while(randomColor === colorNumber) {
            randomColor = Math.floor(Math.random() * colorsData.length);
        }

        setStyle("#" + colorsData[randomColor].code);
        background.setAttribute("data-bg", randomColor);
    }

  return (
    <>
    <div className="background" style={{backgroundColor: backgroundColor}} data-bg="0"></div>
    <main className="home container blend-difference">
        <div className="home__info blend-difference text-light">
            <a href="mailto:hello@jaar.studio" className="home__info-email text-upper text-underline fs-regular fw-regular" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>hello@jaar.studio</a>
            <span className="home__info-time text-upper fs-regular fw-regular">Local time {time}</span>
        </div>
        <div className="home__links text-light blend-difference">
            <ul>
                <li className="home__link text-upper text-underline fs-regular fw-regular"><a href="https://www.behance.net/jaar_studio" target="_blank" rel="noreferrer" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>Behance</a></li>
                <li className="home__link text-upper text-underline fs-regular fw-regular"><a href="https://www.instagram.com/jaardesignstudio" target="_blank" rel="noreferrer" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>Instagram</a></li>
                <li className="home__link text-upper text-underline fs-regular fw-regular"><a href="https://www.twitter.com/jaar_studio" target="_blank" rel="noreferrer" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>Twitter</a></li>
                <li className="home__link text-upper text-underline fs-regular fw-regular"><a href="https://www.facebook.com/jaardesignstudio" target="_blank" rel="noreferrer" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>Facebook</a></li>
                <li className="home__link text-upper text-underline fs-regular fw-regular"><a href="https://www.linkedin.com/in/j%C3%A4%C3%A4r-studio-a1b56421b/" target="_blank" rel="noreferrer" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>LinkedIn</a></li>
            </ul>
        </div>
        <div className="home__heading blend-difference text-light">
            <h1 className="home__heading-title fs-regular fw-regular" id="home__heading-title-1">Website Under Construction</h1>
            <h1 className="home__heading-title fs-regular fw-regular" id="home__heading-title-2">Website <br /> Under <br /> Construction</h1>
            <div className="home__heading-button" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={backgroundColorChange}></div>
        </div>
        <img src={Logo} alt="logo" className="home__logo blend-difference" />
    </main>
    </>
  )
}

export default Home