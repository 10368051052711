const colorsData = [
    {
        id: 0,
        code: "FFFFFF"
    },
    {
        id: 1,
        code: "000000"
    },
    {
        id: 2,
        code: "4141EF"
    },
    {
        id: 3,
        code: "9ECAD8"
    },
    {
        id: 4,
        code: "FFE000"
    },
    {
        id: 5,
        code: "DD2626"
    },
    {
        id: 6,
        code: "043F16"
    },
    {
        id: 7,
        code: "EEA7F2"
    },
    {
        id: 8,
        code: "00EDFF"
    },
    {
        id: 9,
        code: "423732"
    }
]

export default colorsData;