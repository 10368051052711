// import About from './about/About';
import './App.css';

import Cursor from './components/cursor/Cursor';
import Home from './components/home/Home';
import Preloader from './components/preloader/Preloader';
// import SmoothScrollBar from './components/smoothScrollBar/SmoothScrollBar';

function App() {
  return (
    <div className="App">
      <Preloader />
      <Cursor />
      {/* <SmoothScrollBar /> */}
      <div className="Content">
        <Home />
        {/* <About /> */}
      </div>
    </div>
  );
}

export default App;
