import React from 'react'

import { gsap, TimelineLite, Bounce, Power1 } from 'gsap';
import { CustomEase } from "gsap/CustomEase";

import './Preloader.css';

const Preloader = () => {

    const bounceSquare = () => {

        var tl = new TimelineLite();
        var topPos = "0";

        CustomEase.create("preloaderEase", "M0,0 C0.862,0.042 0.372,0.924 1,1 ")

        tl.to("#preloader__box-1", 0.7, {marginTop: topPos, ease:Bounce.easeOut})
        .to("#preloader__box-2", 0.7, {marginTop: topPos, ease:Bounce.easeOut}, "=-0.6")
        .to("#preloader__box-3", 0.7, {marginTop: topPos, ease:Bounce.easeOut}, "=-0.6")
        .to("#preloader__box-4", 0.7, {marginTop: topPos, ease:Bounce.easeOut}, "=-0.6")
        .to("#preloader", 1, {marginTop: "100vh", ease:"preloaderEase"}, "=-0.4")
    }

    React.useEffect(() => {
      setTimeout(() => {
        bounceSquare();
      }, 500)
    }, [])

    gsap.registerPlugin(TimelineLite, Bounce, Power1, CustomEase)

  return (
    <div className="preloader" id="preloader" onClick={bounceSquare}>
        <div className="preloader__box" id="preloader__box-1"></div>
        <div className="preloader__box" id="preloader__box-2"></div>
        <div className="preloader__box" id="preloader__box-3"></div>
        <div className="preloader__box" id="preloader__box-4"></div>
    </div>
  )
}

export default Preloader